<template>
  <DataTable :value="webinars"
             :lazy="true"
             :paginator="true"
             :rows="perPage"
             :totalRecords="totalCount"
             :loading="isLoading"
             :sortField="sortField"
             :sortOrder="sortOrder"
             :filters="filters"
             @page="onPage($event)"
             @sort="onSort($event)"
             responsiveLayout="scroll"
  >
    <Column field="id" filterField="id" header="Идентификатор">
      <template #body="slotProps">
        <span class="identity-column">{{ slotProps.data.id }}</span>
      </template>
    </Column>

    <Column field="link" header="Ссылка">
      <template #body="slotProps">
        <a :href="slotProps.data.link">{{ slotProps.data.link }}</a>
      </template>
    </Column>

    <Column field="channel.name" header="Канал"/>
    <Column field="status.name" header="Статус"/>

    <Column field="started_at" header="Дата начала" :sortable="true">
      <template #body="slotProps">
        {{ $filters.unixToHuman(slotProps.data.started_at) }}
      </template>
    </Column>

    <Column :exportable="false">
      <template #body="slotProps">
        <Button title="Подробней" icon="pi pi-info" class="p-button-rounded p-button-primary"
                @click="showDetail(slotProps.data)"/>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import _ from 'lodash';

export default {
  data() {
    return {
      filters: [],
      currentPage: 1,
      pageCount: null,
      perPage: 20,
      totalCount: null,
      isLoading: true,
      sortField: 'started_at',
      sortOrder: -1,

      showDetailModal: false,
      detailModalData: null,
    }
  },
  emits: [
    'edit-webinar',
    'update:webinars',
  ],
  props: {
    webinarClient: null,
    webinars: {
      type   : Array,
      default: () => []
    },
  },
  interval: null,
  mounted() {
    this.loadList(1, this.sortField, this.sortOrder)
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    onPage(event) {
      this.loadListDebounced(event.page + 1, event.sortField, event.sortOrder, this);
    },

    onSort(event) {
      this.loadListDebounced(1, event.sortField, event.sortOrder, this);
    },

    loadListDebounced: _.debounce((page, sortField, sortOrder, vm) => {
      vm.loadList(page, sortField, sortOrder);
    }, 1000),

    loadList(page = 1, sortField, sortOrder, showLoading = true) {
      showLoading && (this.isLoading = true);

      this.sortField = sortField;
      this.sortOrder = sortOrder;

      this.webinarClient.list(page, sortField, sortOrder).then((list) => {
        this.loadValues(list)
        showLoading && (this.isLoading = false);
      })
    },

    loadValues(list) {
      this.updateWebinars(list.webinars);
      this.currentPage = list.currentPage;
      this.pageCount = list.pageCount;
      this.perPage = list.perPage;
      this.totalCount = list.totalCount;
    },


    showDetail(webinar) {
      this.$emit('edit-webinar', webinar)
    },

    updateWebinars(webinars) {
      this.$emit('update:webinars', webinars);
    }
  }
}
</script>