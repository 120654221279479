"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var DictionaryClient = /** @class */ (function () {
    function DictionaryClient() {
    }
    DictionaryClient.prototype.list = function () {
        var _this = this;
        return axios_1.default.get(this.getListUrl())
            .then(function (response) {
            return response.data.map(function (webinar) { return _this.populate(webinar); });
        });
    };
    DictionaryClient.prototype.populate = function (model) {
        return {
            id: model.id,
            name: model.name,
        };
    };
    return DictionaryClient;
}());
exports.default = DictionaryClient;
