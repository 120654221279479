<template>
  <WebinarDataTable :webinars="webinars" :webinar-client="webinarClient" v-on:edit-webinar="showEdit" @update:webinars="updateWebinars"/>

  <Dialog v-model:visible="showEditModal" style="width: 80%">
    <WebinarForm v-model="webinar" :webinar-client="webinarClient" @update:model-value="updateWebinarRow"/>
  </Dialog>
</template>

<script>
import WebinarClient    from "@/services/webinar/WebinarClient.js";
import WebinarDataTable from "@/components/webinar/WebinarDataTable";
import WebinarForm from "@/components/webinar/WebinarForm";

export default {
  components: {
    WebinarDataTable: WebinarDataTable,
    WebinarForm: WebinarForm,
  },
  data() {
    return {
      webinars        : [],
      webinar       : null,
      showEditModal : false,
      webinarClient : null,
    }
  },
  interval  : null,
  created() {
    this.webinarClient = new WebinarClient();
  },
  methods   : {
    showEdit(webinar) {
      this.webinar       = webinar;
      this.showEditModal = true;
    },

    hideEdit() {
      this.webinar = null;
      this.showEditModal = false;
    },

    updateWebinarRow(value) {
      this.webinar = value;

      let updatedIndex = this.webinars.findIndex(webinar => webinar.id === value.id);

      this.webinars[updatedIndex] = value;

      this.showEditModal = false;
    },

    updateWebinars(webinars) {
      this.webinars = webinars;
    }
  }
}
</script>