<template>
  <div></div>
  <h3 v-if="modelValue.id">{{ "Вебинар #" + modelValue.id }}</h3>
  <h3 v-else>Добавить вебинар</h3>


  <div class="p-fluid">
    <div class="p-field">
      <label for="link">Ссылка</label>
      <InputText id="link" type="text" v-model="link"/>
    </div>
    <div class="p-field">
      <label for="started_at">Дата начала</label>
      <input id="started_at" type="datetime-local" class="p-inputtext p-component" v-model="started_at">
    </div>

    <div class="p-field">
      <label for="channel_id">Канал</label>
      <Dropdown
          id="channel_id"
          v-model="channel_id"
          :options="channels"
          :dropdown="true"
          field="name"
          :filter="false"
          :optionLabel="(value) => value.name"
          :optionValue="(value) => value.id"
          emptyFilterMessage="По вашему запросу ничего не найдено"
      />
    </div>

    <div class="p-field">
      <label for="status_id">Статус</label>
      <Dropdown
          id="status_id"
          v-model="status_id"
          :options="statuses"
          :dropdown="true"
          field="name"
          :filter="false"
          :optionLabel="(value) => value.name"
          :optionValue="(value) => value.id"
          emptyFilterMessage="По вашему запросу ничего не найдено"
      />
    </div>

    <Button label="Сохранить" @click="save"/>
  </div>
</template>

<script>
import ChannelClient       from "@/services/webinar/ChannelClient.js";
import WebinarStatusClient from "@/services/webinar/WebinarStatusClient.js";

export default {
  data() {
    return {
      id            : null,
      link          : null,
      started_at    : null,
      channel_id    : null,
      status_id     : null,
      channels      : [],
      statuses      : [],
      statusesClient: null,
      channelsClient: null,
    }
  },
  emits  : ['update:modelValue'],
  props  : {
    modelValue    : {
      type: Object
    },
    payloadColumns: {
      type   : Array,
      default: () => []
    },
    webinarClient : null,
  },
  created() {
    this.channelsClient = new ChannelClient();
    this.statusesClient = new WebinarStatusClient();
  },
  mounted() {
    this.channelsClient.list().then((data) => this.channels = data);
    this.statusesClient.list().then((data) => this.statuses = data);
    this.fillProps()
  },
  methods: {
    fillProps() {
      this.id         = this.modelValue.id
      this.started_at = this.$filters.toDateTimeInput(this.modelValue.started_at);
      this.status_id  = this.modelValue.status_id;
      this.channel_id = this.modelValue.channel_id;
      this.link       = this.modelValue.link;
    },

    save() {
      let webinar = {
        id        : this.id,
        started_at: this.started_at,
        status_id : this.status_id,
        channel_id: this.channel_id,
        link      : this.link,
      };


      if (webinar.id) {
        return this.webinarClient.save(webinar).then((webinar) => this.updateValue(webinar));
      }

      this.webinarClient.save(webinar).then(() => this.$router.push('/webinar'));
    },

    updateValue(value) {
      this.$emit('update:modelValue', value);
    },

  }
}
</script>