<template>
  <WebinarForm v-model="webinar" :webinar-client="webinarClient"/>
</template>

<script>
import WebinarForm from "@/components/webinar/WebinarForm";
import WebinarClient from "@/services/webinar/WebinarClient.js";

export default {
  data() {
    return {
      webinar: {
        id: null,
        started_at: Date.now(),
        link: null,
        status_id: null,
        channel_id: null,
      },

      webinarClient: null,
    }
  },
  created() {
    this.webinarClient = new WebinarClient();
  },
  components: {
    WebinarForm: WebinarForm
  }
}
</script>