"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var WebinarClient = /** @class */ (function () {
    function WebinarClient() {
    }
    WebinarClient.prototype.list = function (page, sortField, sortOrder) {
        var _this = this;
        return axios_1.default.get(this.getListUrl(), {
            params: {
                sort: this.getSort(sortField, sortOrder),
                page: page,
                include: 'status,channel'
            }
        })
            .then(function (response) {
            var webinars = response.data.data.map(function (webinar) { return _this.populate(webinar); });
            return {
                webinars: webinars,
                currentPage: Number.parseInt(response.data.current_page),
                pageCount: Number.parseInt(response.data.last_page),
                perPage: Number.parseInt(response.data.per_page),
                totalCount: Number.parseInt(response.data.total),
            };
        });
    };
    WebinarClient.prototype.populate = function (webinar) {
        return {
            id: webinar.id,
            started_at: webinar.started_at,
            link: webinar.link,
            status_id: webinar.status_id,
            channel_id: webinar.channel_id,
            status: webinar.status,
            channel: webinar.channel,
        };
    };
    WebinarClient.prototype.save = function (webinar) {
        return webinar.id === null ? this.create(webinar) : this.update(webinar);
    };
    WebinarClient.prototype.update = function (webinar) {
        var _this = this;
        return axios_1.default.put(this.getUpdateUrl(webinar.id), webinar)
            .then(function (response) { return _this.populate(response.data); });
    };
    WebinarClient.prototype.create = function (webinar) {
        var _this = this;
        return axios_1.default.post(this.getCreateUrl(), webinar)
            .then(function (response) { return _this.populate(response.data); });
    };
    WebinarClient.prototype.getListUrl = function () {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_WEBINAR_URL;
    };
    WebinarClient.prototype.getUpdateUrl = function (webinarId) {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_WEBINAR_URL + '/' + webinarId;
    };
    WebinarClient.prototype.getCreateUrl = function () {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_WEBINAR_URL;
    };
    WebinarClient.prototype.getSort = function (sortField, sortOrder) {
        return sortOrder > 0 ? sortField : '-' + sortField;
    };
    return WebinarClient;
}());
exports.default = WebinarClient;
